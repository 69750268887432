import 'animate.css';
import FacebookPixel from 'components/FacebookPixel';
import Loader from 'components/Loader';
import {
  DATADOG_APP_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_RUM_DEPLOYMENT_VERSION,
  DATADOG_RUM_ENV,
  DATADOG_RUM_SERVICE,
  isMockingEnvEnabled,
} from 'constant';
import useGoogleFonts from 'hooks/useGoogleFonts';
import trackingShortUrl from 'lib/shorturlTracking';
import { get } from 'lodash';
import { appWithTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import '../i18n';
import * as ga from '../lib/ga';
import '../styles/App.less';
import '../styles/main.css';

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
});

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', (url) => {
  ga.event({ action: 'pageview', label: url });
  return NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

function MyApp({ Component, pageProps }: AppProps) {
  const [mswState, setMswState] = useState<'unused' | 'loading' | 'ready'>('unused');

  useEffect(() => {
    console.log(`[MSW ${process.env.NEXT_PUBLIC_IA_ENV}] Current MSW State: ${mswState}`);
  }, [mswState]);

  useEffect(() => {
    ga.event({ action: 'pageview', label: window?.location?.href });
    trackingShortUrl();
  }, []);

  const fontsList = [
    get(pageProps, 'data.micrositeConfigDetails.micrositeTheme.fontType'),
    get(pageProps, 'data.micrositeConfigDetails.micrositeTheme.titleFontType'),
  ];

  useGoogleFonts(fontsList);

  // MSW requires initialization of its mock server inside the server code so it can intercept requests being
  // made by Next in getServerSideProps. Unfortunately it cannot be initialized from Cypress since it's
  // running in a separate process.
  if (isMockingEnvEnabled && mswState === 'unused') {
    setMswState('loading');
    const mockImport = import('../mocks/index');
    mockImport.then(({ initMocks }) => initMocks()).then(() => setMswState('ready'));
  }

  if (mswState === 'loading') {
    // If MSW is enabled we must wait for it to start up before rendering to make sure all requests
    // are captured for consistency
    return <Loader />;
  }

  return (
    <FacebookPixel>
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Head>
        <Component {...pageProps} />
      </>
    </FacebookPixel>
  );
}

datadogRum.init({
  applicationId: DATADOG_APP_ID || '',
  clientToken: DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: DATADOG_RUM_SERVICE,
  env: DATADOG_RUM_ENV,
  version: DATADOG_RUM_DEPLOYMENT_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

export default appWithTranslation(MyApp, { i18n });
